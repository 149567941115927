import * as React from 'react';
import type { HeadFC } from 'gatsby';

const pageStyles = {
  color: '#232129',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const linkStyles = {
  fontFamily: 'Pacifico, cursive',
  padding: '10px 20px',
  fontSize: '24px',
  textDecoration: 'none',
  borderRadius: '20px',
  backgroundColor: '#FFD700',
  color: 'black',
  border: '2px solid #DAA520', // Adds a border to create more depth
  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(255,255,255, 0.7)', // Creates a shadow and highlight effect for depth
  transition: 'transform 0.2s, box-shadow 0.2s',
  ':hover': {
    transform: 'translateY(-2px)',
    boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.5), -3px -3px 15px rgba(255,255,255, 0.9)' // Increases the shadow and highlight effect on hover
  }
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <a
        style={linkStyles}
        href="https://youtu.be/K0OSfbPJFa4?si=WZqNxFev29ZwGdJl&t=56"
        target="_blank"
        rel="noopener noreferrer"
      >
        You're a rockstaaar
      </a>
    </main>
  );
};

export default IndexPage;

const isProd = process.env.NODE_ENV === 'production';

export const Head: HeadFC = () => (
  <>
    <title>{isProd ? 'Morgan Qualtrics Site' : 'Local: Morgan Qualtrics Site'}</title>
    <meta name="icon" data-href="src/assets/favicon-32x32.png" />
    <link
      href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap"
      rel="stylesheet"
    />
  </>
);

if (!isProd) {
  document.documentElement.style.backgroundColor = 'gray';
}
